import {
  useAddress,
  useNetwork,
  useContract,
  ConnectWallet,
  Web3Button,
  useNFTBalance,
  useContractRead,
} from '@thirdweb-dev/react';
import { useState, useEffect, useMemo } from 'react';
import { AddressZero } from '@ethersproject/constants';
import { formatEther } from 'ethers/lib/utils';


const App = () => {
  const address = useAddress();
  const network = useNetwork();


  console.log('👋 Address:', address);

  const editionDropAddress = '0x2F833a48059E561fDdfCE45eB8dc1588D37d0846';
  const { contract: editionDrop } = useContract(editionDropAddress, 'edition-drop');
  const { contract: token } = useContract('0xad7825e37d80966A16050c7D3ca38FE94CbE4BBE', 'token');
  const { contract: vote } = useContract('0xFd6AEdd62c2cD9CF0BD73813b8d722Def1ed13f8', 'vote');

  

  const { data: nftBalance } = useNFTBalance(editionDrop, address, '0');

  const proposalId = "62175529863566711407159113141215741877672292790753794787036649968905385263001";

  const hasClaimedNFT = useMemo(() => {
    return nftBalance && nftBalance.gt(0);
  }, [nftBalance]);

  const [memberTokenAmounts, setMemberTokenAmounts] = useState([]);
  const [memberAddresses, setMemberAddresses] = useState([]);

  const shortenAddress = (str) => {
    return str.substring(0, 6) + '...' + str.substring(str.length - 4);
  };

  console.log("Proposal ID being used:", proposalId);
  const { data: proposalVotes, isLoading: proposalVotesLoading } = useContractRead(vote, "proposalVotes", [proposalId]);

  const [proposals, setProposals] = useState([]);
  const [isVoting, setIsVoting] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);

  useEffect(() => {
    if (!proposalVotesLoading) {
      console.log("Votes Loaded: ", proposalVotes);
      if (proposalVotes) {
        console.log(`Against Votes: ${formatEther(proposalVotes.againstVotes)}`);
        console.log(`For Votes: ${formatEther(proposalVotes.forVotes)}`);
        console.log(`Abstain Votes: ${formatEther(proposalVotes.abstainVotes)}`);
      } else {
        console.log("No votes data available.");
      }
    } else {
      console.log("Loading votes...");
    }
  }, [proposalVotes, proposalVotesLoading]);

  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }
  
    const getAllProposals = async () => {
      try {
        const proposals = await vote.getAll();
        const proposalsWithState = proposals.map((proposal) => {
          const now = new Date().getTime();
          const isActive = now >= proposal.startBlock.timestamp && now <= proposal.endBlock.timestamp;
          return { ...proposal, isActive };
        });
        setProposals(proposalsWithState);
        console.log('🌈 Proposals:', proposalsWithState);
      } catch (error) {
        console.log('failed to get proposals', error);
      }
    };
    getAllProposals();
  }, [hasClaimedNFT, vote]);

  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }

    if (!proposals.length) {
      return;
    }

    const checkIfUserHasVoted = async () => {
      try {
        const hasVoted = await vote.hasVoted(proposals[0].proposalId, address);
        setHasVoted(hasVoted);
        if (hasVoted) {
          console.log('🥵 User has already voted');
        } else {
          console.log('🙂 User has not voted yet');
        }
      } catch (error) {
        console.error('Failed to check if wallet has voted', error);
      }
    };
    checkIfUserHasVoted();
  }, [hasClaimedNFT, proposals, address, vote]);

  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }

    const getAllAddresses = async () => {
      try {
        const memberAddresses = await editionDrop?.history.getAllClaimerAddresses(0);
        setMemberAddresses(memberAddresses);
        console.log('🚀 Members addresses', memberAddresses);
      } catch (error) {
        console.error('failed to get member list', error);
      }
    };
    getAllAddresses();
  }, [hasClaimedNFT, editionDrop?.history]);

  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }

    const getAllBalances = async () => {
      try {
        const amounts = await token?.history.getAllHolderBalances();
        setMemberTokenAmounts(amounts);
        console.log('👜 Amounts', amounts);
      } catch (error) {
        console.error('failed to get member balances', error);
      }
    };
    getAllBalances();
  }, [hasClaimedNFT, token?.history]);

  const memberList = useMemo(() => {
    return memberAddresses.map((address) => {
      const member = memberTokenAmounts?.find(({ holder }) => holder === address);

      return {
        address,
        tokenAmount: member?.balance.displayValue || '0',
      };
    });
  }, [memberAddresses, memberTokenAmounts]);
  if (address && !network[0].data.chains.some(chain => chain.chainId === 654)) {
    return (
      <div className="unsupported-network">
        <h2>Please connect to Kalichain</h2>
        <p>
          This dapp only works on the Kalichain network, please switch networks in
          your connected wallet.
        </p>
      </div>
    );
  }
  console.log(network)
  

  if (!address) {
    return (
      <div className="landing">
        <h1>Welcome to the DAO</h1>
        <ConnectWallet theme='light' />
      </div>
    );
  }

  if (address && network?.[0]?.data?.chain?.chainId !== 654) {
    return (
      <div className="unsupported-network">
        <h2>Please connect to Kalichain</h2>
        <p>This dapp only works on the Kalichain network, please switch networks in your connected wallet.</p>
      </div>
    );
  }

  if (hasClaimedNFT) {
    return (
      <div className="member-page">
        <div className="landing" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <h1>KALICHAIN DAO</h1>
          <img src="/logo192.png" alt="KALICHAIN Logo" style={{ maxWidth: '150px', marginTop: '20px' }} />
          <p>Congratulations on being a member</p>
        </div>
        <div>
          <div>
            <h2>Member List</h2>
            <table className="card">
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Token Amount</th>
                </tr>
              </thead>
              <tbody>
                {memberList.map((member) => {
                  return (
                    <tr key={member.address}>
                      <td>{shortenAddress(member.address)}</td>
                      <td>{member.tokenAmount}</td>
                    </tr>
                  );
                  
                })}
              </tbody>
            </table>
          </div>
          <div>
            <h2>Proposals</h2>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                e.stopPropagation();

                console.log("Début du processus de vote...");
                setIsVoting(true);

                console.log("Votes en cours de préparation...");
                const votes = proposals
                  .filter((proposal) => proposal.state === 1)
                  .map((proposal) => {
                    const voteResult = {
                      proposalId: proposal.proposalId,
                      vote: 2,
                    };
                    proposal.votes.forEach((vote) => {
                      const elem = document.getElementById(`${proposal.proposalId}-${vote.type}`);
                      if (elem.checked) {
                        voteResult.vote = vote.type;
                        console.log(`Vote pour la proposition ${proposal.proposalId} : ${vote.type}`);
                      }
                    });
                    return voteResult;
                  });

                try {
                  const delegation = await token.getDelegationOf(address);
                  console.log(`Délégation actuelle pour ${address} : `, delegation);
                  if (delegation === AddressZero) {
                    console.log("Délégation des tokens nécessaire.");
                    await token.delegateTo(address);
                    console.log("Tokens délégués avec succès.");
                  }

                  console.log("Soumission des votes...");
                  await Promise.all(
                    votes.map(async ({ proposalId, vote: _vote }) => {
                      console.log(`Vote sur la proposition ${proposalId} avec le vote ${_vote}`);
                      return vote.vote(proposalId, _vote);
                    })
                  );

                  console.log("Vérification de l'exécution des propositions...");
                  await Promise.all(
                    votes.map(async ({ proposalId }) => {
                      const proposal = await vote.get(proposalId);
                      if (proposal.state === 4) {
                        console.log(`Exécution de la proposition ${proposalId}`);
                        return vote.execute(proposalId);
                      }
                    })
                  );

                  console.log("Votes soumis avec succès.");
                  setHasVoted(true);
                } catch (err) {
                  console.error("Erreur lors du processus de vote : ", err);
                } finally {
                  setIsVoting(false);
                  console.log("Fin du processus de vote.");
                }
              }}
            >
              {proposals
                .filter((proposal) => proposal.state === 1)
                .map((proposal) => (
                  <div key={proposal.proposalId} className="card">
                    <h5>{proposal.description}</h5>
                    <div>
                      {proposal.votes.map(({ type, label }) => (
                        <div key={type}>
                          <input
                            type="radio"
                            id={`${proposal.proposalId}-${type}`}
                            name={proposal.proposalId}
                            value={type}
                            defaultChecked={type === 2}
                          />
                          <label htmlFor={`${proposal.proposalId}-${type}`}>
                            {label}
                          </label>
                        </div>
                      ))}
                    </div>
                    {!proposalVotesLoading && proposalVotes && (
                      <div>
                        <p>Against Votes: {formatEther(proposalVotes.againstVotes)} KALIS</p>
                        <p>For Votes: {formatEther(proposalVotes.forVotes)} KALIS</p>
                        <p>Abstain Votes: {formatEther(proposalVotes.abstainVotes)} KALIS</p>
                      </div>
                    )}
                  </div>
                ))}
              {!hasVoted && (
                <button type="submit" disabled={isVoting}>
                  {isVoting ? 'Voting...' : 'Submit Votes'}
                </button>
              )}
              {!hasVoted && (
                <small>
                  This will trigger multiple transactions that you will need to
                  sign.
                </small>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mint-nft">
      <h1>Mint your free KALIS DAO Membership NFT</h1>
      <div className="btn-hero">
        <Web3Button
          contractAddress={editionDropAddress}
          action={(contract) => {
            contract.erc1155.claim(0, 1);
          }}
          onSuccess={() => {
            console.log(
              `🌊 Successfully Minted! Check it out on OpenSea: https://explorer.kalichain.com/token/${editionDrop.getAddress()}/0`,
            );
          }}
          onError={(error) => {
            console.error('Failed to mint NFT', error);
          }}
        >
          Mint your NFT (FREE)
        </Web3Button>
      </div>
    </div>
  );
};

export default App;