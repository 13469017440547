import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { Kalichain } from "@thirdweb-dev/chains";



// Import thirdweb provider
import { ThirdwebProvider } from "@thirdweb-dev/react";



// Wrap your app with the thirdweb provider
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
   <ThirdwebProvider
      activeChain={ Kalichain }
      clientId="557bffa5f2b967c6bcc86ff4e3bbc243" // You can get a client id from dashboard settings
    >
    <App />
    </ThirdwebProvider>
  </React.StrictMode>
);
